import { createBrowserRouter } from "react-router-dom";
import CompanyLogin from "./authentication/company/login";
import ForgetPassword from "./authentication/forgetpassword";
import Dashboard from "./dashboard";
import CompanyHome from "./dashboard/module/company/home";
import CompanyMessage from "./dashboard/module/company/messages";
import CompanyProfile from "./dashboard/module/company/myProfile";
import CompanyRequest from "./dashboard/module/company/requests";
import CompanySetting from "./dashboard/module/company/settings";
import CompanySite from "./dashboard/module/company/sites";
import CompanySubcription from "./dashboard/module/company/subscriptions";
import LandingPage from "./landingPage";

import AddWorker from "./dashboard/module/company/workers/addWorker";
import EditWorker from "./dashboard/module/company/workers/editWorker";
// import TermCondition from "./dashboard/module/company/settings/termConditions";
// import PrivacyPolicies from "./dashboard/module/company/settings/privacyPolicies";
import CheckInOutRequest from "./dashboard/module/company/home/checkInOutRequest";
import CompanyNotification from "./dashboard/module/company/notification/index";

import PrivateRoute from "./PrivateRoute";
import CompanyChangePassword from "./dashboard/module/company/settings/changePassword";
import WorkFroce from "./dashboard/module/company/workFroce";
import AddWorkFroce from "./dashboard/module/company/workFroce/addWorkFroce";
import Workers from "./dashboard/module/company/workers";
import ViewWokers from "./dashboard/module/company/workers/viewWokers";
import Payment from "./payment";

// import TermConditions from "./dashboard/module/supervisor/settings/termConditions";
// import PrivacyPolicy from "./dashboard/module/supervisor/settings/privacyPolicies";
import NotFoundPage from "./pageNotFound";

//------ Policies -----------
import CookiesPolicy from "./policies/cookiespolicy";
import PrivacyPolicies from "./policies/privacypolicies";
// import TermCondition from "./policies/termcondition";
import Blog from "./blogs";
import SingleBlog from "./blogs/singleblog";
import BillingCompany from "./dashboard/module/company/billingPayment";
import Failed from "./payment/Failed";
import Success from "./payment/Success";
import AboutUs from "./policies/aboutus";
import Eula from "./policies/eula";
import RefundPolicy from "./policies/refundpolicy";
import TermConditions from "./policies/terms";
import Tutorials from "./tutorials";
import SingleTutuorials from "./tutorials/singleblog";
import ContactUs from "./policies/contact";
import UnsubscribeForm from "./landingPage/UnsubscribeForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/login",
    element: <CompanyLogin />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  //--- policies start ------
  {
    path: "/privacypolicy",
    element: <PrivacyPolicies />,
  },
  {
    path: "/cookies",
    element: <CookiesPolicy />,
  },
  {
    path: "/refundpolicy",
    element: <RefundPolicy />,
  },
  {
    path: "/eula",
    element: <Eula />,
  },
  {
    path: "/contactUs",
    element: <ContactUs />,
  },
  {
    path: "/termscondition",
    element: <TermConditions />,
  },
  {
    path: "/blogs",
    element: <Blog />,
  },
  {
    path: "/blog/:slug",
    element: <SingleBlog />,
  },
  {
    path: "/tutorials",
    element: <Tutorials />,
  },
  {
    path: "/tutorials/:slug",
    element: <SingleTutuorials />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },

  ///----------policy end ------
  //setNewPassword
  {
    path: "/success",
    element: <PrivateRoute element={<Success />} fallbackPath="/" />,
  },
  {
    path: "/cancel",
    element: <PrivateRoute element={<Failed />} fallbackPath="/" />,
  },

  {
    path: "/payment",
    element: <PrivateRoute element={<Payment />} fallbackPath="/" />,
  },

  {
    path: "/unsubscribe/:email/&ul=Cw8NUQ8HTE5UUQ5QQBUIXwZaEANABlVxVAxQCghKWlsJREk=/:id",
    element: <UnsubscribeForm />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard/company/home",
        element: (
          <PrivateRoute element={<CompanyHome />} fallbackPath="/login" />
        ),
      },
      //-------------company----------------
      {
        path: "/dashboard/company/requests",
        element: (
          <PrivateRoute element={<CompanyRequest />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/workforce",
        element: <PrivateRoute element={<WorkFroce />} fallbackPath="/login" />,
      },
      {
        path: "/dashboard/company/addworkforce",
        element: (
          <PrivateRoute element={<AddWorkFroce />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/messages",
        element: (
          <PrivateRoute element={<CompanyMessage />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/subscriptions",
        element: (
          <PrivateRoute
            element={<CompanySubcription />}
            fallbackPath="/login"
          />
        ),
      },
      {
        path: "/dashboard/company/billing",
        element: (
          <PrivateRoute element={<BillingCompany />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/sites",
        element: (
          <PrivateRoute element={<CompanySite />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/notification",
        element: (
          <PrivateRoute
            element={<CompanyNotification />}
            fallbackPath="/login"
          />
        ),
      },
      {
        path: "/dashboard/company/settings",
        element: (
          <PrivateRoute element={<CompanySetting />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/myProfile",
        element: (
          <PrivateRoute element={<CompanyProfile />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/wokers",
        element: <PrivateRoute element={<Workers />} fallbackPath="/login" />,
      },
      {
        path: "/dashboard/company/viewWokers",
        element: (
          <PrivateRoute element={<ViewWokers />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/addwoker",
        element: <PrivateRoute element={<AddWorker />} fallbackPath="/login" />,
      },
      {
        path: "/dashboard/company/editworker",
        element: (
          <PrivateRoute element={<EditWorker />} fallbackPath="/login" />
        ),
      },
      {
        path: "/dashboard/company/checkinoutrequest",
        element: (
          <PrivateRoute element={<CheckInOutRequest />} fallbackPath="/login" />
        ),
      },

      //-------------company----------------
      {
        path: "/dashboard/company/changepassword",
        element: (
          <PrivateRoute
            element={<CompanyChangePassword />}
            fallbackPath="/login"
          />
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
    fallbackPath: "/",
  },
]);

export default router;
