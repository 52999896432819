import http from "../baseURL/httpcommon";
import authHeader from "../baseURL/authHeader";

// const signUp = (data) => {
//   return http.post("auth/company/signup", data);
// };
const signUp = (data) => {
  return http.post("/auth/company/sub/signup", data);
};
const pricePlann = (plans) => {
  return http.get(`subscription/allSubscriptionPlan?planType=${plans}`);
};
const sendMessageApi = (data) => {
  return http.post("/contact/save", data);
};

const getLatestBlogs = () => {
  return http.get(`/blog/latest`);
};

const getLatestTutorials = () => {
  return http.get(`/tutorials/latest`);
};

const getCompanySettings = () => {
  return http.get(`/settings/company`);
};
const companySignUp = {
  signUp,
  pricePlann,
  sendMessageApi,
  getLatestBlogs,
  getLatestTutorials,
  getCompanySettings,
};

export default companySignUp;
