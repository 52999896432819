import React, { useEffect, useState } from "react";
import SubscriptionServices from "./services";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ServerSideTable from "../../../common components/serverSideTable";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import companySignUp from "../../../../landingPage/services";
import { Modal } from "react-bootstrap";

function convertTimestampToDate(timestamp) {
  // Convert the timestamp to milliseconds (JavaScript timestamps are in milliseconds)
  const date = new Date(timestamp * 1000);

  // Format the date as desired
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return formattedDate;
}

const CompanySubcription = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [show, setShow] = useState(false);
  const [sortBy, setSortBy] = useState("createdAt"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  const [currentSubData, setCurrentSubData] = useState({});

  const jwt = jwtDecode(localStorage.getItem("token"));
  const userId = jwt.id;
  const companyId = jwt.companyId;
  console.log("userID", userId);

  const columns = [
    { field: "transactionId", headerName: "Transaction Id", width: 200 },
    { field: "currency", headerName: "Currency", width: 100, sortable: false },
    {
      field: "amount",
      headerName: "Paid Amount",
      sortable: false,
      width: 150,
      valueFormatter: (params) => {
        // Ensure the value is a number and format it to two decimal places
        return params.value ? params.value.toFixed(2) : "";
      },
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 150,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 210,
      valueFormatter: (params) => {
        return params.value ? convertTimestampToDate(params?.value) : "";
      },
    },
    {
      field: "paymentMethodTypes",
      headerName: "Payment Methods",
      sortable: false,
      width: 150,
      valueFormatter: (params) => {
        // Join the array elements into a comma-separated string
        return params.value ? params.value.join(", ") : "";
      },
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  //get invoice records
  const getInvoice = async () => {
    debugger;
    try {
      const res = await SubscriptionServices.getInvoiceData(
        page,
        pageSize,
        userId,
        sortBy,
        sortDir
      );
      debugger;
      const { totalPages, totalElements, currentPage, content } = res.data.data;
      console.log("data_invoice" + res.data.data);
      setCount(totalPages);
      setTotalItems(totalElements);
      setInvoiceData(content);
    } catch (error) {
      setMessage(error.message);
      setSeverity("error");
      // setOpen(true);
    }
    setLoading(false);
  };
  //get subscription record
  const getSubscription = async () => {
    debugger;
    try {
      const response = await SubscriptionServices.getSubscriptionData();

      if (response.status == 200) {
        debugger;
        const subData = response.data.data;
        console.log("userCurrentSub", subData);
        setSubscription(subData);
      }
    } catch (error) {
      //   if (error.response.status === 404) {
      //     setMessage(error.response.data.message);
      //     setSeverity("error");
      //     setOpen(true);
      //   } else if (error.response.status === 400) {
      //     console.log("Missing Parameter");
      //   } else if (error.response.status === 500) {
      //     console.log("Internal server error");
      //   }
      setMessage(error.message);
      setSeverity("error");
      // setOpen(true);
    }
  };

  useEffect(() => {
    getCompanies();
    getCompanyCurrentSubscription();
  }, []);

  useEffect(() => {
    if (page !== undefined) {
      getInvoice();
      getSubscription();
    }
  }, [page, pageSize, sortBy, sortDir]);

  const getCompanyCurrentSubscription = () => {
    SubscriptionServices.getCompanyCurrentSubscription(userId)
      .then((response) => {
        setCurrentSubData(response.data.data);
        console.log("currentSub", response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [planeToggal, setPlaneToggal] = useState("1");
  const [getPricePlane, setGetPricePlane] = useState([]);
  const [load, setLoad] = useState(false);
  const [professionalPrice, setProfessionalPrice] = useState(20);
  const [selectedPlan, setSelectedPlans] = useState("Monthly");
  const [planId, setPlanId] = useState(1);
  const [price, setPrice] = useState(0);
  const [shareCount, setShareCount] = useState(0);
  const [plans, setPlans] = useState("Monthly");
  const [highlight, setHighlight] = useState(false);

  const handleShow = () => setShow(true);
  const handleCloseModel = () => setShow(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    const numberRegex = /^[0-9\b]+$/;
    if (
      (numberRegex.test(newValue) || newValue === "") &&
      (newValue === "" || parseInt(newValue) <= 500)
    ) {
      setProfessionalPrice(newValue);
    }
  };

  function getPlane() {
    console.log("plantype", plans);
    companySignUp
      .pricePlann(plans)
      .then((response) => {
        if (response.status === 200) {
          console.log("pricePlann", response.data.data);
          const data = response.data.data;
          if (data.length > 0) {
            setGetPricePlane(data);
            setLoad(true);
          } else {
            setMessage("No Plan Found");
            setSeverity("error");
            setOpen(true);
          }
        } else if (response.status === 400) {
          console.error("Bad request:", response.data);
        } else if (response.status === 404) {
          console.error("Not found:", response.data);
        } else if (response.status === 500) {
          console.error("Server error:", response.data);
        } else if (response.status === 204) {
          console.error("No content:", response.data);
          setMessage("No Plan Found");
          setSeverity("error");
          setOpen(true);
        } else {
          console.error("Unexpected error:", response.data);
        }
      })
      .catch((error) => {
        console.error("Request failed:", error);
        // Handle network error or other general errors
      });
  }

  useEffect(() => {
    getPlane();
  }, [plans]);

  const [btnLoading, setBtnLoading] = useState(false);

  const dataJson = {
    userId: userId,
    planId: "",
    paymentGatewayType: "",
    price: "",
    shareCount: "",
  };
  const [data, setData] = useState(dataJson);

  const [successPlan, setSuccessPlan] = useState([]);

  const renewalPlan = () => {
    setBtnLoading(true);
    SubscriptionServices.renewalPlan(userId)
      .then((response) => {
        setSuccessPlan(response.data.data);
        setMessage(response.data.message);
        setSeverity("success");
        setOpen(true);
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setMessage("Something went wrong!");
        setSeverity("error");
        setOpen(true);
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="p-3 pt-0 bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-6 col-lg-6">
              <h5 className="mb-0 font-18">Subscriptions</h5>
            </div>
            <div className="col-6 col-md-6 col-lg-6 text-right"></div>
          </div>
        </div>
        <div className="card-body">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-7 col-lg-7">
              <div className="border p-4 mb-4 border-20">
                <div className="d-flex mb-3 xs-block">
                  <label className="form-label text-dark font-18 xs-block">
                    {subscription.planName} Plan
                  </label>{" "}
                  {/* <span className="text-gray font-14 ps-lg-3">$8/month</span> */}
                  {/* <span className="text-gray font-14 ps-lg-3">
                    {subscription.startupAmount
                      ? subscription.startupAmount
                      : 0}{" "}
                    /month
                  </span> */}
                  <div className="ms-auto xs-block">
                    <span className="mb-0 font-18">
                      ${subscription.price ? subscription.price : 0}{" "}
                    </span>
                    <span className="text-gray font-14">
                      {subscription.planType
                        ? subscription.planType
                        : "/monthly"}
                    </span>
                  </div>
                </div>
                <div className="d-flex mb-3 xs-block">
                  <label className="text-gray font-14 xs-block">
                    Active Licenses:{" "}
                    {subscription.activeUserCount
                      ? subscription.activeUserCount
                      : 0}
                  </label>{" "}
                  <span className="text-gray font-14 ms-lg-4">
                    Remaining Licenses:{" "}
                    {subscription.remainingCount
                      ? subscription.remainingCount
                      : 0}
                  </span>
                  <div className="ms-auto xs-block">
                    {/* <span className="text-gray font-14">
                      Remaining Licenses: 3
                    </span> */}
                  </div>
                </div>
                <div className="d-flex mb-3 mt-lg-5 xs-block">
                  {/* <Link
                    className="btn btn-primary font-14"
                    title="Click to Upgrade Plan"
                  >
                    
                  </Link> */}
                  <button
                    type="button"
                    className="btn btn-primary font-14"
                    onClick={() => setShow(true)}
                  >
                    {"Upgrade Plan"}
                  </button>
                  <button
                    className="btn btn-primary font-14"
                    style={{ marginLeft: 10 }}
                    onClick={renewalPlan}
                    disabled={btnLoading}
                  >
                    {btnLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Renewal"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mb-3 mt-lg-5">Invoices</h5>
          <div className="table-responsive">
            <ServerSideTable
              pageSize={pageSize}
              page={page}
              sortBy={sortBy}
              sortDir={sortDir}
              count={count}
              functionName={getCompanies}
              data={invoiceData}
              columns={columns}
              loading={loading}
              idname="id"
            />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleCloseModel} centered size="xl">
        <Modal.Header
          closeButton
          className="border-0 mb-0 p-3 pb-0"
        ></Modal.Header>

        <Modal.Body className="font-20 text-dark">
          <section id="plan" className="pricing-area pt-0 pb-70">
            <div className="container">
              <div className="section-title pt-2">
                <h2>Subscription Plans</h2>
                <div className="bar"></div>
              </div>
              <div className="tab pricing-list-tab">
                <ul className="tabs">
                  <li>
                    <button
                      className={`btn ${
                        planeToggal == "1" ? "default-btn2" : null
                      }`}
                      onClick={() => {
                        setPlaneToggal(1);
                        setPlans("Monthly");
                        setSelectedPlans("Monthly");
                      }}
                    >
                      1 month
                    </button>
                  </li>
                  <li>
                    <button
                      className={`btn ${
                        planeToggal == "3" ? "default-btn2" : null
                      }`}
                      onClick={() => {
                        setPlaneToggal(3);
                        setPlans("Yearly");
                        setSelectedPlans("Yearly");
                      }}
                    >
                      1 year
                    </button>
                  </li>
                </ul>
                {load ? (
                  <div className="tab_content">
                    <div className="tabs_item">
                      <div className="row">
                        {/* <div className="col-lg-3 col-md-6">
                          <div className="single-pricing-table">
                            <div className="pricing-header">
                              <h3>Personal</h3>
                            </div>
                            <div className="price mb-4">
                              ${getPricePlane[0].price}
                              <sub> Free</sub>
                            </div>
                            <h6 className="mb-3">
                              For {getPricePlane[0].shareCount} user
                            </h6>
                            <div
                              className="border_bottom"
                              style={{ opacity: 0 }}
                            ></div>
                            <div className="pricing-btn2"></div>
                            <div className="pricing-btn ps-3">
                              <span
                                style={{ cursor: "pointer" }}
                                className="default-btn3 px-5"
                                onClick={() => (
                                  handleShow(),
                                  setPlanId(getPricePlane[0]?.planId),
                                  setPrice(getPricePlane[0]?.priceOfPlan),
                                  setShareCount(getPricePlane[0]?.shareCount)
                                )}
                              >
                                Sign up
                              </span>
                            </div>
                            <div className="border_bottom"></div>
                            <ul>
                              {getPricePlane[0].features.map((item) => (
                                <li>{item.features}</li>
                              ))}
                            </ul>
                            <ul></ul>
                          </div>
                        </div> */}

                        <div className="col-lg-4 col-md-6">
                          <div className="single-pricing-table">
                            <div className="pricing-header">
                              <h3>{getPricePlane[1].planName}</h3>
                            </div>
                            <div className="price mb-4">
                              ${getPricePlane[1].price}{" "}
                              <sub>
                                {" "}
                                {selectedPlan === "Monthly"
                                  ? "/month"
                                  : "/year"}
                              </sub>
                            </div>
                            <h6 className="mb-3">
                              {getPricePlane[1].shareCount} users
                            </h6>
                            <div className="border_bottom"></div>
                            <div className="pricing-btn2">
                              <h6 className="">
                                {" "}
                                Total ${getPricePlane[1].priceOfPlan}{" "}
                                {selectedPlan === "Monthly"
                                  ? "/month"
                                  : "/year"}
                              </h6>
                            </div>
                            <div className="pricing-btn ps-3">
                              <span
                                style={{ cursor: "pointer" }}
                                className="default-btn3 px-5"
                                onClick={() => (
                                  handleShow(),
                                  setPlanId(getPricePlane[1]?.planId),
                                  setPrice(getPricePlane[1]?.priceOfPlan),
                                  setShareCount(getPricePlane[1]?.shareCount)
                                )}
                              >
                                Select
                              </span>
                            </div>
                            <div className="border_bottom"></div>
                            <ul>
                              {getPricePlane[1].features.map((item) => (
                                <li>{item.features}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-pricing-table">
                            <div className="pricing-header">
                              <h3>{getPricePlane[2].planName}</h3>
                            </div>
                            <div className="price mb-2">
                              ${getPricePlane[2].price}{" "}
                              <sub>
                                per user
                                {selectedPlan === "Monthly"
                                  ? "/month"
                                  : "/year"}
                              </sub>
                            </div>
                            <h6 className="">
                              <input
                                type="text"
                                maxLength={3}
                                className="form-control  d-inline"
                                placeholder="20"
                                value={professionalPrice}
                                id="number"
                                style={{ width: "100px" }}
                                onChange={handleChange}
                              />{" "}
                              Users
                            </h6>
                            <div className="border_bottom"></div>
                            <div className="pricing-btn2">
                              <h6 className="mb-3">
                                {professionalPrice >= 20 ? (
                                  <>
                                    Total $
                                    {(
                                      getPricePlane[2].price * professionalPrice
                                    ).toFixed(2)}
                                    {selectedPlan === "Monthly"
                                      ? "/month"
                                      : "/year"}
                                  </>
                                ) : (
                                  <span
                                    style={{ fontSize: "12px", color: "#f00" }}
                                  >
                                    Please add users 20 or more then{" "}
                                  </span>
                                )}
                              </h6>
                            </div>
                            <div className="pricing-btn ps-3">
                              <span
                                style={{ cursor: "pointer" }}
                                className="default-btn3 px-5"
                                onClick={() => (
                                  handleShow(),
                                  setPlanId(getPricePlane[2]?.planId),
                                  setPrice(getPricePlane[2].price),
                                  setShareCount(professionalPrice)
                                )}
                              >
                                Select
                              </span>
                            </div>
                            <div className="border_bottom"></div>
                            <ul>
                              {getPricePlane[2].features.map((item) => (
                                <li>{item.features}</li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div
                          id="enterprice"
                          style={{
                            backgroundColor: highlight
                              ? "yellow"
                              : "transparent",
                          }}
                          className="col-lg-4 col-md-6"
                        >
                          <div className="single-pricing-table2">
                            <div className="pricing-header">
                              <h3 className="text-white">Enterprise</h3>
                            </div>
                            <div className="pric mb-4 text-center py-5">
                              <img
                                src="/assets/img/buildings.png"
                                alt="building"
                                height={50}
                                width={50}
                              />
                            </div>
                            <div className="pricing-btn ps-3 mt-5">
                              <Link
                                style={{ cursor: "pointer" }}
                                to={`/contactUs`}
                                // onClick={() => handleShowForm()}
                                className="default-btn3 px-5"
                              >
                                Contact us
                              </Link>
                            </div>
                            <div className="border_bottom mt-5"></div>
                            <p className="ps-3 text-white">
                              For construction companies seeking robust
                              governance and administration features at an
                              enterprise level.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CompanySubcription;
