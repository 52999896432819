import { useEffect, useRef } from "react";
import Worker from "./services";
import { useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import WorkForceApi from "./services";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import SiteApi from "../sites/services";

const WorkFroce = () => {
  const jwt = jwtDecode(localStorage.getItem("token"));
  const companyId = jwt?.companyId;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [sortBy, setSortBy] = useState("name"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("asc"); // Added state for sortDir

  const pageRef = useRef(null);
  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  function changeList() {
    WorkForceApi.getworkForce(
      page,
      pageSize,
      companyId,
      name,
      supervisorId,
      siteId,
      sortBy,
      sortDir
    ).then((respons) => {
      const { totalPages, totalElements, currentPage, content } =
        respons.data.data;
      setCount(totalPages);
      setTotalItems(totalElements);
      setData(content);
      setLoading(false);
    });
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (page !== undefined) {
      changeList();
    }
  }, [page, pageSize, sortBy, sortDir]);

  const columns = [
    { field: "name", headerName: "Workforce Name", width: 180 },
    {
      field: "supervisorId",
      headerName: "Supervisor",
      width: 180,
      renderCell: (param) => {
        return <span>{param.row.supervisorName}</span>;
      },
    },
    {
      field: "companySites",
      headerName: "Site Name",
      width: 220,
      renderCell: (param) => {
        return <span>{param.row.siteName}</span>;
      },
    },
    {
      field: "workers",
      headerName: "Workers",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return <span>{param.row.workers}</span>;
      },
    },
    {
      field: "tasks",
      headerName: "Tasks",
      width: 100,
      sortable: false,
      renderCell: (param) => {
        return <span>{param.row.tasks.length}</span>;
      },
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      renderCell: (param) => {
        return (
          <Link
            to="/dashboard/company/addworkforce"
            state={{ data: param.row, view: true }}
            className="btn btn-outline-secondary btn-blue font-13 me-2"
          >
            <span className="badge bg-danger rounded-circle"></span> View
            Workforce
          </Link>
        );
      },
      width: 150,
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //to get set the focus on whole page when scroolbar appear on page
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    pageRef.current.scrollIntoView({ behavior: "auto" });
  }, []);

  const [companySites, setCompanySites] = useState([]);
  const [supervisors, setSupervisors] = useState([]);

  const getCompanySites = () => {
    SiteApi.getBoundary(companyId)
      .then((response) => {
        setCompanySites(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getSupervisors = () => {
    WorkForceApi.getCompanySupervisor(companyId)
      .then((response) => {
        setSupervisors(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getCompanySites();
    getSupervisors();
  }, []);

  console.log("companySites", companySites);

  const [name, setName] = useState("");
  const [supervisorId, setSupervisorId] = useState("");
  const [siteId, setSiteId] = useState("");

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeSupervisorId = (e) => {
    const supervisorId = e.target.value;
    setSupervisorId(supervisorId);
  };

  const onChangeSiteId = (e) => {
    const siteId = e.target.value;
    setSiteId(siteId);
  };

  const handleSearch = () => {
    changeList();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row" ref={pageRef}>
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Workforce")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Link
                    to="/dashboard/company/addworkforce"
                    className="btn btn-outline-secondary btn-blue font-14 ms-2"
                  >
                    <span className="badge bg-danger rounded-circle"></span> Add
                    Workforce
                  </Link>
                </div>
              </div>

              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-4 col-xl-4">
                  <TextField
                    fullWidth
                    id="name"
                    size="small"
                    name="name"
                    label="Search by Name"
                    variant="outlined"
                    value={name}
                    onChange={onChangeName}
                  />
                </div>

                <div className="col-12 col-lg-3 col-xl-3">
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Sites Name
                    </InputLabel>
                    <Select
                      labelId="payment-status"
                      id="payment-status"
                      label="Payment Status"
                      value={siteId}
                      onChange={onChangeSiteId}
                    >
                      <MenuItem value="">All</MenuItem>
                      {companySites.map((item) => (
                        <MenuItem value={item.siteId}>{item.siteName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-12 col-lg-3 col-xl-3">
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Supervisor Name
                    </InputLabel>
                    <Select
                      labelId="payment-status"
                      id="payment-status"
                      label="Payment Status"
                      value={supervisorId}
                      onChange={onChangeSupervisorId}
                    >
                      <MenuItem value="">All</MenuItem>
                      {supervisors.map((item) => (
                        <MenuItem value={item.userId}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-12 col-lg-2 col-xl-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </div>
              </div>
              <ServerSideTable
                pageSize={pageSize}
                page={page}
                sortBy={sortBy}
                sortDir={sortDir}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="id"
              />
            </div>
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};
export default WorkFroce;
