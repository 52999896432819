import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GoogleMap from "./googleMap";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import SiteApi from "./services";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { jwtDecode } from "jwt-decode";
import ShowGoogleMap from "./showGoogleMap";
import EditGoogleMap from "./editGoogleMap";
import { useTranslation } from "react-i18next";
import { isValidAlphanumeric } from "../../../../helper/regex";

const CompanySite = () => {
  const jwt = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const companyId = jwt?.companyId;
  const [show, setShow] = useState(false);
  const [boundaryView, setBoundaryView] = useState(false);
  const [editBoundaryView, setEditBoundaryView] = useState(false);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setOpen(false);
  };

  const boundaryViewClose = () => setBoundaryView(false);
  const editboundaryClose = () => {
    setEditBoundaryView(false);
    setValidated(false);
  };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [getBoundaryValue, setGetBoundaryValue] = useState([]);
  function getBoundary() {
    SiteApi.getBoundary(companyId).then((respons) => {
      console.log(respons);
      setGetBoundaryValue(respons.data.data);
    });
    // fetch(`https://tappme-api.digitalnoticeboard.biz/tappme-api-staging/company/site/12`).then((respons) => {
    //    respons.json().then((result) => {
    //       console.log("fetch respons", result.data)
    //       setGetBoundaryValue(result.data)
    //    })

    // })
  }
  useEffect(() => {
    getBoundary();
  }, []);

  const schemaData = {
    siteName: "",
    boundaryAddress: "",
    centerLatitude: "",
    centerLongitude: "",
    polygonVertices: [],
    zoomLevel: "",
  };
  const [data, setData] = useState(schemaData);
  const [editData, setEditData] = useState(schemaData);

  const boundaryViewShow = (
    centerLatitude,
    centerLongitude,
    polygonVertices,
    siteName,
    boundaryAddress,
    zoomLevel
  ) => {
    setBoundaryView(true);
    setData({
      ...data,
      centerLatitude: centerLatitude,
      centerLongitude: centerLongitude,
      polygonVertices: polygonVertices,
      siteName: siteName,
      boundaryAddress: boundaryAddress,
      zoomLevel: zoomLevel,
    });
  };
  const [siteId, setSiteId] = useState("");
  const editBoundaryViewShow = (
    siteId,
    centerLatitude,
    centerLongitude,
    polygonVertices,
    siteName,
    boundaryAddress,
    zoomLevel
  ) => {
    setEditBoundaryView(true);
    setEditData({
      ...editData,
      centerLatitude: centerLatitude,
      centerLongitude: centerLongitude,
      polygonVertices: polygonVertices,
      siteName: siteName,
      boundaryAddress: boundaryAddress,
      zoomLevel: zoomLevel,
    });
    setSiteId(siteId);
    console.log(
      "prop get",
      siteId,
      centerLatitude,
      centerLongitude,
      polygonVertices,
      siteName,
      boundaryAddress,
      zoomLevel
    );
  };

  const handleShow = () => {
    setShow(true);
    setData(schemaData);
  };
  function setValue(event) {
    const { name, value } = event.target;

    if (name === "siteName" && !isValidAlphanumeric(value)) {
      return;
    }
    if (name === "boundaryAddress" && !isValidAlphanumeric(value)) {
      return;
    }

    setData({ ...data, [name]: value });
  }

  function setEditValue(event) {
    const { name, value } = event.target;

    if (name === "siteName" && !isValidAlphanumeric(value)) {
      return;
    }
    if (name === "boundaryAddress" && !isValidAlphanumeric(value)) {
      return;
    }
    setEditData({ ...editData, [name]: value });
  }

  const [centerLatitude, setCenterLatitude] = useState(0);
  const [centerLongitude, setCenterLongitude] = useState(0);
  const [polygonVertices, setPolygonVertices] = useState();
  const [zoomLevel, setZoomLevel] = useState("18");

  function propValue(lat, long, polygonVertices, zoomLevel) {
    //   function propValue(polygonVertices, zoomLevel) {
    debugger;
    console.log("propValue front Side", polygonVertices, zoomLevel);
    //  setData({ ...data, centerLatitude: lat, centerLongitude: long, polygonVertices: polygonVertices })
    //  console.log("lat_&_long" + lat + "," + long);
    setCenterLatitude(lat);
    setCenterLongitude(long);
    setPolygonVertices(polygonVertices);
    setZoomLevel(zoomLevel);
  }

  useEffect(() => {
    setData({
      ...data,
      centerLatitude: centerLatitude,
      centerLongitude: centerLongitude,
    });
    console.log(
      "centerLatitude" + centerLatitude + "centerLongitude" + centerLongitude
    );
  }, [centerLatitude, centerLongitude]);
  useEffect(() => {
    console.log("useEffect value", centerLatitude, "|", centerLongitude);
    if (centerLatitude !== "" && centerLongitude !== "") {
      setEditData({
        ...editData,
        centerLatitude: centerLatitude,
        centerLongitude: centerLongitude,
      });
    }
  }, [centerLatitude, centerLongitude]);

  function saveData() {
    SiteApi.addBoundary({
      ...data,
      centerLatitude: centerLatitude,
      centerLongitude: centerLongitude,
      polygonVertices: polygonVertices,
      zoomLevel: zoomLevel,
    }).then((respone) => {
      console.log(respone);
      handleClose();
      setOpen(true);
      setMessage(respone.data.message);
      setSeverity("success");
      getBoundary();
      setData(schemaData);
      console.log(data);
    });
  }

  function updateData() {
    SiteApi.editBoundary(siteId, {
      ...editData,
      centerLatitude: centerLatitude,
      centerLongitude: centerLongitude,
      polygonVertices: polygonVertices,
      zoomLevel: zoomLevel,
    }).then((response) => {
      console.log(response);
      setOpen(true);
      setMessage(response.data.message);
      setSeverity("success");
      getBoundary();
      setEditData(schemaData);
      editboundaryClose();
    });
  }
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    console.log("data.polygonVertices", data.polygonVertices, polygonVertices);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (data.siteName.trim() !== "" && data.boundaryAddress.trim() !== "") {
        if (data.centerLatitude !== "" && data.centerLongitude !== "") {
          if (polygonVertices.length >= 3) {
            saveData();
          } else {
            setOpen(true);
            setMessage("Please create an 3 set boundary");
            setSeverity("error");
          }
        } else {
          setOpen(true);
          setMessage("Please choose the location");
          setSeverity("error");
        }
      } else {
        setOpen(true);
        setMessage("Please fill all mandatory fields");
        setSeverity("error");
      }
    }

    setValidated(true);
  };

  const handleEditSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (
        editData.siteName.trim() !== "" &&
        editData.boundaryAddress.trim() !== ""
      ) {
        if (editData.centerLatitude !== "" && editData.centerLongitude !== "") {
          if (polygonVertices.length >= 3) {
            updateData();
          } else {
            setOpen(true);
            setMessage("Please create an 3 set boundary");
            setSeverity("error");
          }
        } else {
          setOpen(true);
          setMessage("Please choose the location");
          setSeverity("error");
        }
      } else {
        setOpen(true);
        setMessage("Please fill all mandatory fields");
        setSeverity("error");
      }
    }

    setValidated(true);
  };
  //show data of google map

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = getBoundaryValue?.filter((item) =>
    item.siteName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-7 col-xl-7">
                  <h5 className="mb-0 font-18">{t("Construction_Sites")}</h5>
                </div>

                <div className="col-12 col-lg-5 col-xl-5 text-right">
                  <button
                    className="btn btn-outline-secondary btn-blue font-14 ms-2"
                    onClick={handleShow}
                  >
                    Create new site
                  </button>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Search by Site Name")}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              {filteredData.length > 0 ? (
                filteredData?.map((item) => (
                  <div className="card radius-15">
                    <div className="card-body border radius-10">
                      <div className="row align-items-center d-flex">
                        <div className="col-12 col-lg-6 col-xl-6">
                          <p className="mb-0">{item.siteName}</p>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4 pxx-2">
                          {/* <button className="btn btn-outline-secondary btn-blue font-14 ms-2" type="button">200 worker assigned</button> */}
                        </div>
                        <div className="col-12 col-lg-2 col-xl-2 px-2">
                          <button
                            className="btn btn-primary font-14 ms-2 mb-0"
                            onClick={() =>
                              boundaryViewShow(
                                item.centerLatitude,
                                item.centerLongitude,
                                item.polygonVertices,
                                item.siteName,
                                item.boundaryAddress,
                                item.zoomLevel
                              )
                            }
                          >
                            View
                          </button>
                          <button
                            className="btn btn-primary font-14 ms-2 mb-0"
                            onClick={() =>
                              editBoundaryViewShow(
                                item.siteId,
                                item.centerLatitude,
                                item.centerLongitude,
                                item.polygonVertices,
                                item.siteName,
                                item.boundaryAddress,
                                item.zoomLevel
                              )
                            }
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Site Found</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body font-20 p-0 text-dark">
              <div className="row  align-items-center d-flex">
                <div className="col-12 col-lg-6 col-xl-6 mb-3">
                  <div className="px-4 pt-4">
                    <p className="font-20 text-dark  text-center">
                      New Construction Site
                    </p>
                    <form className="row">
                      <div className="col-12 col-lg-12 col-xl-12 mb-2">
                        <label for="exampleDataList" className="form-label">
                          Enter Site name
                        </label>
                        <input className="form-control" placeholder="" />
                      </div>
                      <div className="col-12 col-lg-12 col-xl-12 mb-2">
                        <label className="form-label">Boundary Address</label>
                        <input
                          type="type"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-12 pb-4">
                        <button
                          type="button"
                          className="btn btn-primary mt-4 me-2"
                        >
                          Create Site
                        </button>
                        <button type="button" className="btn btn-danger mt-4">
                          Cancel{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 ps-0">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54891.38608484392!2d76.65609494030552!3d30.698388675064155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee906da6f81f%3A0x512998f16ce508d8!2sSahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1704695175838!5m2!1sen!2sin"
                    width="100%"
                    height="500px"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Geofence Boundary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6 mb-3">
              <div className=" pt-1">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group controlId="validationCustom01">
                    <Form.Label className="text-gray mb-2">
                      Site name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      maxLength={150}
                      placeholder="Enter site name"
                      name="siteName"
                      value={data.siteName}
                      onChange={setValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Site Name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="validationCustom01" className="mt-2">
                    <Form.Label className="text-gray mb-2">
                      Boundary address <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      maxLength={150}
                      placeholder="Enter boundary address"
                      name="boundaryAddress"
                      value={data.boundaryAddress}
                      onChange={setValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter boundary address
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row className="mt-2">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label className="text-gray mb-2">
                        Latitude{" "}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        disabled
                        name="centerLatitude"
                        value={data.centerLatitude}
                        onChange={setValue}
                      />
                      {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label className="text-gray mb-2">
                        Longitude
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        disabled
                        name="centerLongitude"
                        value={data.centerLongitude}
                        onChange={setValue}
                      />
                      {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 ps-0">
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54891.38608484392!2d76.65609494030552!3d30.698388675064155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee906da6f81f%3A0x512998f16ce508d8!2sSahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1704695175838!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              <GoogleMap data={data} propValue={propValue} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add Geofence Boundary
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={boundaryView}
        onHide={boundaryViewClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Geofence Boundary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6 mb-3">
              <div className=" pt-1">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group controlId="validationCustom01">
                    <Form.Label className="text-gray mb-2">
                      Site Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter site name"
                      name="siteName"
                      disabled
                      value={data.siteName}
                      onChange={(e) => setValue(e)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="validationCustom01" className="mt-2">
                    <Form.Label className="text-gray mb-2">
                      Boundary Address <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Boundary Address"
                      name="boundaryAddress"
                      disabled
                      value={data.boundaryAddress}
                      onChange={(e) => setValue(e)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Row className="mt-2">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label className="text-gray mb-2">
                        Latitude{" "}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        disabled
                        name="centerLatitude"
                        value={data.centerLatitude}
                        onChange={(e) => setValue(e)}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label className="text-gray mb-2">
                        Longitude
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        disabled
                        name="centerLongitude"
                        value={data.centerLongitude}
                        onChange={(e) => setValue(e)}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 ps-0">
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54891.38608484392!2d76.65609494030552!3d30.698388675064155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee906da6f81f%3A0x512998f16ce508d8!2sSahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1704695175838!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              {/* <GoogleMap data={data} propValue={propValue} /> */}

              <ShowGoogleMap data={data} />
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                  Close
               </Button>
               <Button variant="primary" onClick={handleSubmit}>Add Geofence Boundary </Button>
            </Modal.Footer> */}
      </Modal>

      <Modal
        show={editBoundaryView}
        onHide={editboundaryClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{editData.siteName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6 mb-3">
              <div className=" pt-1">
                <Form noValidate validated={validated}>
                  <Form.Group controlId="validationCustom01">
                    <Form.Label className="text-gray mb-2">
                      Site name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter site name"
                      name="siteName"
                      value={editData.siteName}
                      onChange={(e) => setEditValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter site name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="validationCustom01" className="mt-2">
                    <Form.Label className="text-gray mb-2">
                      Boundary address <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter boundary address"
                      name="boundaryAddress"
                      value={editData.boundaryAddress}
                      onChange={(e) => setEditValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter boundary address
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row className="mt-2">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label className="text-gray mb-2">
                        Latitude{" "}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        disabled
                        name="centerLatitude"
                        value={editData.centerLatitude}
                        onChange={(e) => setEditValue(e)}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label className="text-gray mb-2">
                        Longitude
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        disabled
                        name="centerLongitude"
                        value={editData.centerLongitude}
                        onChange={(e) => setEditValue(e)}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 ps-0">
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54891.38608484392!2d76.65609494030552!3d30.698388675064155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee906da6f81f%3A0x512998f16ce508d8!2sSahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1704695175838!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              <EditGoogleMap data={editData} propValue={propValue} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={editboundaryClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Update Geofence Boundary
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={boundaryViewClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CompanySite;
