import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

//add worker
const addWorker = (data) => {
  return http.post(`auth/user/signup`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

//update Worker
const updateWorker = (userId, data) => {
  return http.put(`user/update/${userId}`, data, {
    headers: { ...authHeader() },
  });
};

const fileUpload = (data, config) => {
  return http.post(`upload/doc`, data, config, {
    headers: {
      ...authHeader(),
    },
  });
};
const imgeUpload = (data, config) => {
  return http.post(`upload/image`, data, config, {
    headers: {
      ...authHeader(),
    },
  });
};

const getWorker = (page, pageSize, name, email, phoneNo, sortBy, sortDir) => {
  return http.get(
    `company/worker/list?pageNumber=${page}&pageSize=${pageSize}&name=${name}&email=${email}&phoneNo=${phoneNo}&sortBy=${sortBy}&sortDir=${sortDir}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const languages = () => {
  return http.get(`application/worker/languages`);
};

const changeStatus = (email, event) => {
  console.log("status", email, event);
  const data = {
    username: email,
    status: event,
  };
  return http.patch(`company/worker/change/status`, data, {
    headers: {
      ...authHeader(),
    },
  });
};
const supervisorStatus = (email, event) => {
  const data = {
    username: email,
    status: event,
  };
  return http.patch(`company/worker/change/role`, data, {
    headers: {
      ...authHeader(),
    },
  });
};
const workerAprroval = (requestId, status) => {
  debugger;
  return http.put(
    `request/worker/status?requestId=${requestId}&status=${status}`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};
const workerskill = () => {
  return http.get(`admin/skills/active`);
};

const Worker = {
  addWorker,
  languages,
  getWorker,
  fileUpload,
  imgeUpload,
  changeStatus,
  supervisorStatus,
  workerAprroval,
  workerskill,
  updateWorker,
};

export default Worker;
