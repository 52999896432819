import { useEffect, useRef } from "react";
import Worker from "./services";
import { useState } from "react";
import { Button, Chip, Switch, TextField } from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Workers = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const pageRef = useRef(null);
  const [sortBy, setSortBy] = useState("createdAt"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePhoneNo = (e) => {
    const phoneNo = e.target.value;
    setPhoneNo(phoneNo);
  };

  function changeList() {
    setLoading(true);
    Worker.getWorker(
      page,
      pageSize,
      name,
      email,
      phoneNo,
      sortBy,
      sortDir
    ).then((respons) => {
      const { totalPages, totalElements, currentPage, content } =
        respons.data.data;
      // const updatedContent = content.map((company) => {
      //   return { ...company, id: company.userId };
      // });
      setCount(totalPages);
      setTotalItems(totalElements);
      setData(content);
      setLoading(false);
    });
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    changeList();
  }, [page, pageSize, sortBy, sortDir]);

  const columns = [
    { field: "userId", headerName: "Worker Id", width: 100 },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "",
      headerName: "Is Supervisor?",
      sortable: false,
      flex: 1,
      renderCell: (param) => {
        function changesupervisorStatus(email, event) {
          console.log("status", !event);
          Worker.supervisorStatus(email, event)
            .then((respons) => {
              setOpen(true);
              setMessage(respons.data.message);
              setSeverity("success");
              changeList();
            })
            .catch((e) => {
              console.log(e.response.data.status);
              if (e.response.data.status == false) {
                setOpen(true);
                setMessage(e.response.data.message);
                setSeverity("error");
              }
            });
        }
        return (
          <Switch
            lable="test"
            color="success"
            checked={param.row.isSupervisor}
            onChange={(e) =>
              changesupervisorStatus(param.row.username, e.target.checked)
            }
          />
        );
      },
    },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phoneNo", headerName: "Phone No", flex: 1 },
    {
      field: "userProfile",
      headerName: "Address",
      sortable: false,
      flex: 1,
      renderCell: (param) => {
        return <span>{param.row.userProfile.address}</span>;
      },
    },
    {
      field: "status",
      headerName: "Is Enabled",
      sortable: false,
      flex: 1,
      renderCell: (param) => {
        function changeStatus(email, event) {
          console.log("status", !event);
          Worker.changeStatus(email, event)
            .then((respons) => {
              setOpen(true);
              setMessage(respons.data.message);
              setSeverity("success");
              changeList();
            })
            .catch((e) => {
              console.log(e.response.data.status);
              if (e.response.data.status == false) {
                setOpen(true);
                setMessage(e.response.data.message);
                setSeverity("error");
              }
            });
        }
        return (
          <Switch
            lable="test"
            color="success"
            checked={param.row.status}
            onChange={(e) => changeStatus(param.row.username, e.target.checked)}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      renderCell: (param) => {
        return (
          // <Link
          //   className="outline-primary"
          //   state={{ data: param.row }}
          //   to="/dashboard/company/viewWokers"
          // >
          //   View Details
          // </Link>
          <Link
            className="link-with-border" // Add your custom class here
            state={{ data: param.row }}
            to="/dashboard/company/viewWokers"
          >
            View Details
          </Link>
        );
      },
      flex: 1,
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //to get set the focus on whole page when scroolbar appear on page
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    pageRef.current.scrollIntoView({ behavior: "auto" });
  }, []);

  const handleSearch = () => {
    changeList();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row" ref={pageRef}>
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18"> {t("Workers")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Link
                    to="/dashboard/company/addwoker"
                    className="btn btn-outline-secondary btn-blue font-14 ms-2"
                  >
                    <span className="badge bg-danger rounded-circle"></span> Add
                    New Worker
                  </Link>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3">
                <div className="col-12 col-lg-4 col-xl-4">
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    size="small"
                    label="Search by Name"
                    variant="outlined"
                    value={name}
                    onChange={onChangeName}
                  />
                </div>

                <div className="col-12 col-lg-3 col-xl-3">
                  <TextField
                    fullWidth
                    size="small"
                    id="email"
                    name="email"
                    label="Search by Email"
                    variant="outlined"
                    value={email}
                    onChange={onChangeEmail}
                  />
                </div>

                <div className="col-12 col-lg-3 col-xl-3">
                  <TextField
                    size="small"
                    fullWidth
                    id="phoneNo"
                    name="phoneNo"
                    label="Search by Phone number"
                    variant="outlined"
                    value={phoneNo}
                    onChange={onChangePhoneNo}
                  />
                </div>

                <div className="col-12 col-lg-2 col-xl-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </div>
              </div>
              <ServerSideTable
                pageSize={pageSize}
                page={page}
                sortBy={sortBy}
                sortDir={sortDir}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="userId"
              />
            </div>
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};
export default Workers;
