import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const Eula = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="container pt-3 pb-4">
        <Link component={RouterLink} to={"/"}>
          <img
            src="/assets/img/tappme-logo.png"
            alt="policy"
            className="desk_tops"
          />
        </Link>
        <h1>
          <strong style={{ fontSize: "30px" }}>
            END-USER LICENSE AGREEMENT (EULA)
          </strong>
        </h1>
        <p className="ms-2">Last Updated: May 12, 2024</p>

        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          This End User License Agreement, including any supplemental terms
          (collectively hereinafter, the "EULA") is a legally binding agreement
          between users (hereinafter, "you," "your," "user," "registered user")
          and{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          (hereinafter, the "Company," "we," "us," "our) and governs your use of{" "}
          TAPP ME (hereinafter, the "App") provided by the Company.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Your use of the App, and any future release, update, or any other
          addition to the functionality thereof, shall be subject to the terms
          prescribed by the Terms and Conditions (hereinafter, T&amp;C) and the
          EULA of{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME
          </a>
          .
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          You are advised to read this EULA and our T&amp;C with attention and
          care. By accessing or using the App, or any software therein, you
          formally accept, give consent, and agree to the terms of the EULA and
          T&amp;C. If you do not agree with the prescribed T&amp;C and EULA to
          the App, you may not access, use or otherwise download the App.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Upon visiting, accessing, entering, downloading or otherwise using the
          App, you hereby agree and give your formal consent to the use of
          electronic communication to enter into legally binding contracts to
          the extent permitted by applicable laws.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          You hereby waive any rights under any laws and regulations that may
          require an original (non-electric) signature to constitute a legally
          binding contract.
        </p>
        <h3 className="mt-3 mb-3">Ownership</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          The App and all worldwide copyrights, trade secrets, trademarks and
          other intellectual property rights therein, are the exclusive property
          of the Company. We reserve all rights in and to the App.
        </p>
        <h3 className="mt-3 mb-3">License</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Subject to the terms under this EULA, we hereby grant you a revocable,
          non-exclusive, non-transferable, limited license to access, visit,
          download, install and use the App solely for your personal,
          non-commercial purposes, strictly in accordance with the terms of this
          EULA and T&amp;C. You may, further, use the app to create content,
          strictly for your personal, non-commercial purposes. The App shall not
          be used for any other purpose not prescribed in this EULA.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          reserves the exclusive right to revoke, terminate or limit your
          license and/or use of the App without notice.
        </p>
        <h3 className="mt-3 mb-3">Term and Termination</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          The EULA and the license granted hereunder shall be deemed to come
          into effect on the date you first use, access or download the App, and
          shall continue unless this EULA is terminated by you or{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          .
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          This EULA may terminate on itself without notice from{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          if you fail to comply with any terms of the EULA.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          We reserve the absolute right to terminate this EULA without notice or
          reason.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          You may terminate the EULA at any time, by permanently deleting,
          destroying and returning, at your own cost, the App, all backup
          copies, and all related materials provided by{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          .
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Upon termination of this EULA, the license granted hereunder shall
          terminate and you shall stop all use of the App immediately.
        </p>
        <h3 className="mt-3 mb-3">Restrictions on Users</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          You agree not to, and you shall not permit others to:
        </p>

        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",

            marginBottom: "2rem",
          }}
        >
          <li>
            license, sell, rent, lease, transfer, assign, distribute, transmit,
            host, outsource, disclose or otherwise commercially exploit the App
            or make the App available to any third parties;
          </li>
          <li>
            copy or use the App for any purpose other than your own personal,
            non-commercial purposes;
          </li>
          <li>
            copy or use the App to facilitate any criminal activity, including
            but not limited to hacking; or
          </li>
          <li>
            modify, make derivative works of, disassemble, decrypt, translate,
            reverse compile or reverse engineer any part of the App.
          </li>
        </ul>
        <h3 className="mt-3 mb-3">Limitation of Liability</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Not with standing anything written herein to the contrary, and to the
          extent permitted under applicable laws, you and{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          hereby<strong> </strong>acknowledge and agree that we will not be
          liable for any losses or damages, whether indirect, incidental,
          special, consequential, exemplary, or punitive, or for any loss of
          use, or lost data, even if we have been advised of the possibility of
          such damages.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Notwithstanding anything written herein to the contrary, and to the
          extent permitted under applicable laws, you and{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          hereby<strong> </strong>acknowledge and agree that the total
          cumulative liability in connection with the App, its Terms and
          Conditions and this EULA, shall at all times, whether in contract or
          tort or otherwise, be limited to a maximum of a hundred (U.S. $ 100 /
          USD 100). The existence of more than one claim shall not enlarge or
          deviate or alter this prescribed limit.{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          disclaims any liability of any kind, and you hereby, by accessing,
          downloading, registering, or using the App, give your formal consent
          to this, and every other clause/provision of this EULA.
        </p>
        <h3 className="mt-3 mb-3">Severability and Disclaimers</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Neither the rights nor the obligations arising under this EULA are
          assignable by you, and any such attempted assignment or transfer shall
          be void and without effect. We reserve the right to freely assign this
          EULA.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          The United Nations Convention on Contracts for the International Sale
          of Goods shall not apply to this EULA. Any notice to you may be
          provided by email to the address you have registered with us. We shall
          have no responsibility to provide maintenance or support services with
          respect to the App.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          If any provision of this EULA is unenforceable, such provision shall
          be interpreted to accomplish the objectives of such provision to the
          greatest extent possible under applicable law and the remaining
          provisions shall continue in full force and effect.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          All waivers by us shall be effective only if in writing. Any waiver or
          failure by{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          to enforce any provision of this EULA on one occasion shall not be
          deemed a waiver of any other provision or of such provision on any
          other occasion.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          You acknowledge that the App contains valuable trade secrets,
          trademarks, copyrights, proprietary information and other intellectual
          properties of{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          , that any actual or threatened breach of any section of this EULA
          that may constitute irreparable harm to{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          shall be liable to any and every damages, including but not limited
          to, incidental damages, consequential damages, nominal damages,
          liquidated damages, punitive damages, monetary or any special kind of
          damages permitted by applicable laws, as an appropriate remedy for
          such breach. This EULA and the Terms constitute the final, complete,
          and exclusive agreement between the parties regarding the App and
          supersede all prior or contemporaneous agreements, understandings, and
          communication, whether written or oral.
        </p>

        <h3 className="mt-3 mb-3">INTELLECTUAL PROPERTY</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          You hereby unconditionally agree that all right, title and interest in
          the copyrights and other intellectual property rights in the App
          reside with the{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          . The trademarks, logos, designs, and service marks appearing on the
          App are registered and unregistered marks of{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          . Accordingly, nothing in this EULA or the App grants you any right to
          use any form of intellectual property contained in the App.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Therefore, all rights, titles, interests, and copyrights in and/or to
          the App, including but not limited to all images, graphics,
          animations, audio, video, music, text, data, code, algorithm, and
          information, are owned by the{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          . Accordingly, the App is protected by all applicable copyright laws
          and international treaties, and the Licensee is expected to use the
          App concerning all intellectual property contained therein, except as
          otherwise provided for in this EULA.
        </p>

        <h3 className="mt-3 mb-3">Amendments</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          We reserve the sole right to amend, repeal, modify, replace, terminate
          or make changes to this EULA, and the App without notice, from time to
          time. If we make material changes to this EULA, or the App, we shall
          notify you by sending you an e-mail at your primary email address or
          in any other way convenient for us, as mentioned in your account
          details. Any changes to this EULA, or App, shall be effective
          immediately for new users of our App; otherwise these changes shall be
          effective upon the earliest of thirty (30) calendar days following our
          dispatch of an e-mail notice to you. You are responsible at all times
          for updating your Account to provide to us your most current e-mail
          address. If the last e-mail address that you have provided to us is
          not valid, or for any reason is not capable of delivering the notice
          described above, our dispatch of the e-mail containing such notice
          shall nonetheless constitute effective notice of the changes.
          Continued use of the App following notice of such changes shall
          indicate your acknowledgement of, and EULA to be bound by, such
          changes.
        </p>

        <h2 className="mt-5 mb-4">Disclaimer: </h2>
        <h3>Warranty</h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          To the extent permitted under Applicable laws, you acknowledge that we
          provide the App "as-is" without any warranty of any kind, express or
          implied, and to the maximum extent permitted by Applicable law. We
          disclaim all warranties, whether express, implied, or statutory,
          including but not limited to, warranties of merchantability, fitness
          for a particular purpose, title, quiet enjoyment, accuracy, and
          non-infringement. We do not guarantee any specific results from the
          use of our App. We make no warranty that the App shall be
          uninterrupted, free of viruses, or other harmful code, timely, secure
          or error or bug free.
        </p>

        <h3 className="mt-3 mb-3">
          <strong>CONTACT US</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          For any questions or queries regarding this EULA, please feel free to
          contact us:
        </p>
        <div className="ms-3">
          <strong>
            <a
              href="mailto:info@tappme.se"
              style={{ color: "blue" }}
              target="_blank"
            >
              info@tappme.se
            </a>
          </strong>
        </div>
      </div>
    </>
  );
};

export default Eula;
