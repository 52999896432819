import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ForgetApi from "./services";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import SettingApi from "./services";

const CompanyChangePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [validatedOtp, setValidatedOtp] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [optRecevied, setOptRecevied] = useState(false);
  const [optLoad, setOptLoad] = useState(false);
  const dataJson = {
    username: location.state.userName,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [data, setData] = useState(dataJson);

  const setValue = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    console.log(data);
  };

  const resetPassword = () => {
    setOptLoad(true);
    SettingApi.changePassword(data)
      .then((respons) => {
        setOptLoad(false);
        if (respons.data.status == true) {
          setData({
            ...data,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          setValidatedOtp(false);
          setOpen(true);
          setMessage(respons.data.message);
          setSeverity("success");
        }
      })
      .catch((e) => {
        setOptLoad(false);
        if (
          e.response.status == 401 ||
          e.response.status == 404 ||
          e.response.status == 400
        ) {
          console.log(e.response.status);
          setOpen(true);
          setMessage(e.response.data.message);
          setSeverity("error");
        }
      });
  };
  const resetPasswordSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (
        data.oldPassword !== "" &&
        data.newPassword !== "" &&
        data.confirmPassword !== ""
      ) {
        if (data.newPassword === data.confirmPassword) {
          resetPassword();
        } else {
          setOpen(true);
          setMessage("New Password & Confirm Password is not same");
          setSeverity("error");
        }
      }
    }
    setValidatedOtp(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">Change Password</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right"></div>
                <div className="col">
                  <Form
                    className="form-body pt-2"
                    noValidate
                    validated={validatedOtp}
                  >
                    <div className="col-12 mt-3">
                      <Form.Group
                        controlId="validationCustom01"
                        className="ms-auto position-relative"
                      >
                        <div className="position-absolute translate-middle-y2 search-icon1 px-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                              fill="#1E73FF"
                            />
                          </svg>
                        </div>
                        <Form.Control
                          className="form-control radius-4 ps-5 height-50"
                          required
                          type="password"
                          placeholder="Old Password"
                          name="oldPassword"
                          value={data.oldPassword}
                          onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 mt-3">
                      <Form.Group
                        controlId="validationCustom01"
                        className="ms-auto position-relative"
                      >
                        <div className="position-absolute translate-middle-y2 search-icon1 px-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                              fill="#1E73FF"
                            />
                          </svg>
                        </div>
                        <Form.Control
                          className="form-control radius-4 ps-5 height-50"
                          required
                          type="password"
                          placeholder="Enter New password"
                          name="newPassword"
                          maxLength={15}
                          minLength={8}
                          pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$"
                          value={data.newPassword}
                          onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid">
                          Ex. (Password@123)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 mt-3">
                      <Form.Group
                        controlId="validationCustom01"
                        className="ms-auto position-relative"
                      >
                        <div className="position-absolute translate-middle-y2 search-icon1 px-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                              fill="#1E73FF"
                            />
                          </svg>
                        </div>
                        <Form.Control
                          className="form-control radius-4 ps-5 height-50"
                          required
                          type="password"
                          placeholder="Enter Confirm password"
                          name="confirmPassword"
                          maxLength={15}
                          minLength={8}
                          pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$"
                          value={data.confirmPassword}
                          onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Form>
                  <div className="col-12 mt-3">
                    <div className="d-grid">
                      <button
                        to="/dashboard/admin/home"
                        type="submit"
                        className="btn btn-primary login-btn height-50"
                        onClick={resetPasswordSubmit}
                      >
                        {optLoad ? `Please Wait` : "Change Password"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CompanyChangePassword;
