import http from "../../../../baseURL/httpcommon";

const getBillingData = (
  userId,
  year,
  month,
  page,
  pageSize,
  sortBy,
  sortDir
) => {
  return http.get(
    `/payment/worker/payment?userId=${userId}&year=${year}&month=${month}&pageNumber=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const getWorkerFilterList = (companyId) => {
  return http.get(`/company/search/company/worker/${companyId}`);
};

const BillingServices = {
  getBillingData,
  getWorkerFilterList,
};

export default BillingServices;
