import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const UnsubscribeForm = () => {
  const [reason, setReason] = useState("");

  const handleUnsubscribe = (e) => {
    e.preventDefault();
    // Handle unsubscribe logic here
    console.log("Unsubscribed with reason:", reason);
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          backgroundColor: "#f4f0f0",
          padding: "30px",
          textAlign: "center",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h6" component="h1" gutterBottom>
          abhaysinghsep01@gmail.com
        </Typography>
        <Typography variant="body1" gutterBottom>
          is subscribed to our mailing list(s).
        </Typography>

        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ color: "red", fontWeight: "bold" }}
        >
          Unsubscribe from our mailing list
        </Typography>

        <Typography variant="body2" gutterBottom>
          To help us improve our services, we would be grateful if you could
          tell us why:
        </Typography>

        <FormControl fullWidth sx={{ margin: "20px 0" }}>
          <InputLabel>Please select reason</InputLabel>
          <Select label="Please select reason" defaultValue="">
            <MenuItem value="">
              <em>Please select reason</em>
            </MenuItem>
            <MenuItem value={1}>Too many emails</MenuItem>
            <MenuItem value={2}>Content not relevant</MenuItem>
            <MenuItem value={3}>Other</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          sx={{ padding: "10px 30px", fontSize: "16px" }}
        >
          Unsubscribe
        </Button>
      </Box>
    </Container>
  );
};

export default UnsubscribeForm;
