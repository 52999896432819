import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getNotification = (companyId) => {
  debugger;
  return http.get(`/company/notification/${companyId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const findNotificationById = (id) => {
  return http.get(`/company/notification/get/${id}`);
};

const NotificaionServices = {
  getNotification,
  findNotificationById,
};

export default NotificaionServices;
