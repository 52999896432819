import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const supervisors = (companyId) => {
  return http.get(`user/inactive/supervisors?CompanyId=${companyId}`);
};
const worker = (companyId) => {
  return http.get(`user/inactive/workers?CompanyId=${companyId}`);
};
const assignedWorker = (companyId) => {
  return http.get(`workforce/workforce/${companyId}`);
};
const removeWorker = (WorkerForceId, WorkerId) => {
  return http.post(
    `workforce/remove/worker?workForceId=${WorkerForceId}&userId=${WorkerId}`
  );
};
const updateWorker = (data) => {
  return http.put(`workforce/assign/worker`, data);
};
const getworkForce = (
  page,
  pageSize,
  companyId,
  name,
  supervisorId,
  siteId,
  sortBy,
  sortDir
) => {
  return http.get(
    `workforce/allWorkforce?companyId=${companyId}&name=${name}&supervisorId=${supervisorId}&siteId=${siteId}&pageNumber=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};
const addWorkerForce = (data) => {
  return http.post(`workforce/save`, data, {
    headers: {
      ...authHeader(),
    },
  });
};
const updateWorkerForce = (data) => {
  return http.put(`workforce/update`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

const getCompanySupervisor = (companyId) => {
  return http.get(`/company/supervisors?companyId=${companyId}`);
};

const getWorkforceTasks = (workforceId) => {
  return http.get(`/workforce/tasks/${workforceId}`);
};

const WorkForceApi = {
  supervisors,
  worker,
  removeWorker,
  updateWorker,
  assignedWorker,
  getworkForce,
  getCompanySupervisor,
  addWorkerForce,
  updateWorkerForce,
  getWorkforceTasks,
};

export default WorkForceApi;
